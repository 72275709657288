@mixin flex-container($justify-content: 'flex-start', $align-items: 'stretch', $flex-flow: 'row nowrap', $inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  justify-content: #{$justify-content};
  align-items: #{$align-items};
  flex-flow: #{$flex-flow};
  @content;
}
