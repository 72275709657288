@import 'theme/common-style.scss';

.root {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  top: 44px;
  width: 340px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: $box-shadow;
  z-index: 1;
  padding: 12px;
  top: calc(100% + 10px);
}

.searchBox {
  margin-bottom: 16px;
}

.profileList {
  @include vertical-scrollbar();
  max-height: 325px;
  margin-top: 6px;
  overflow-y: auto;

  &Item ~ &Item {
    margin-top: 6px;
  }
}

.profileListLoaderBox {
  @include flex-container(center, center);
  padding: 24px;
}

.loaderBox {
  @include flex-container(center, center);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $color-white;
}

.loader path {
  fill: $color-secondary;
}

.errorMessage {
  @include font(12);
  color: $color-error;
  text-align: center;
}

.searchInput input {
  @include screen-xl-minus {
    @include font(13);
  }
}
