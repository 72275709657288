@import 'theme/common-style.scss';

.root {
  position: relative;
}

.spinnerArea {
  @include flex-container(center, center);
  background: rgba($color-white, .5);
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  transition: opacity .2s ease-in-out;
}

.visible {
  opacity: 1;
  z-index: 2;
}
