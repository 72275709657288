@import 'theme/common-style.scss';

$sidebar-width: 302px;
$sidebar-width-xl-minus: 260px;

.root {
  display: grid;
  grid-template-columns: $sidebar-width 1fr;
  width: 100vw;

  @include screen-xl-minus {
    grid-template-columns: $sidebar-width-xl-minus 1fr;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 28px 32px 38px 24px;

  &Main {
    flex: 1;
  }

  @include screen-xl-minus {
    padding: 28px 16px 38px 16px;
  }
}

.main {
  position: relative;
  padding-top: 20px;
  padding-right: 25px;
  width: calc(100vw - #{$sidebar-width});

  @include screen-xl-minus {
    width: calc(100vw - #{$sidebar-width-xl-minus});
  }
}

.logoLink {
  @include flex-container(flex-start, center);
}

.logo {
  width: 77px;

  path {
    fill: $color-dark;
  }
}

.search {
  margin: 28px 0;
}

.menuBox {
  @include flex-container(flex-end, center);
  position: absolute;
  top: 11px;
  right: 25px;
  z-index: 4;
}

.menuBoxUsername {
  @include font(15);
  color: $color-gray-cool;
  padding-right: 14px;
}

.menuBoxContent {
  position: relative;
}

.menuBtn {
  @include flex-container(flex-start, center);
  position: relative;
}

.userIconBox {
  @include flex-container(center, center);
  width: 38px;
  height: 38px;
  background-color: $color-gray-light;
  border-radius: 999px;
  margin-right: 9px;
}

.arrowIcon {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);

  &.arrowIconClosed {
    transform: rotate(0deg);
  }
}

.menu {
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
  right: 0;
  margin-top: 12px;
  height: 0;
  transition: height 0.2s;

  &.menuOpen {
    height: 52px;
  }

  ul {
    margin: 10px;
  }

  li {
    padding: 4px 12px;
    width: 100%;

    button {
      font-size: 14px;
      width: 100%;
      font-weight: 400;
      color: $color-dark;
      text-align: left;
      white-space: nowrap;
    }
  }
}

.appVersion {
  @include font(14);
  margin-right: 45px;

  span {
    padding-left: 7px;
    color: $color-primary;
  }
}

.lockedBgForMenu {
  position: fixed;
  inset: 0;
  z-index: 3;
}

.logout {
  @include font(15);
  @include flex-container(flex-start, center);
  position: fixed;
  bottom: 38px;
  color: $color-label;

  svg {
    margin-right: 10px;
  }
}

.prodUiCheckbox {
  margin-right: 45px;
  padding-top: 4px;
}
