@import 'theme/common-style.scss';

.inputBox {
  @include input-box();
}

.textarea {
  resize: none;
  overflow: hidden;
  outline: none;
}
