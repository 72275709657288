@import 'theme/common-style.scss';

.root {
  @include flex-container(flex-start, center);
  flex: 0;
}

.panelCheckbox {
  margin-right: 15px;

  + div ~ div {
    margin-left: 7.5px;
  }
}

.classificationLabel {
  @include flex-container(center, center);
  width: 40px;
  height: 25px;
  border-radius: 13px;
}

.feed {
  background-color: $color-gray-glaucous;
}

.ml {
  background-color: $color-metallic-gold;
}
