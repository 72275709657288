@import 'theme/common-style.scss';

.root {
  width: 100vw;
  height: 100vh;
  background: $color-background;
}

.manualContent {
  padding-top: 28px;
  width: 1138px;
  margin: 0 auto;
}

.main {
  background-color: $color-white;
  padding-top: 68px;
}

.image {
  margin: 8px auto 0;
  padding-top: 5px;
  height: 542px;
}

.header {
  margin: 0 auto -10px;
  width: 445px;
  @include font(36, $font-weight-bold);
}

.bottomContent {
  margin: 19px auto 0;
  display: flex;
  width: 758px;
  padding-bottom: 40px;
  @include flex-container(flex-end, center);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: -49px;
}

.buttonNumber.selectedButton {
  background: $color-primary-gradient;
  color: $color-white;
}

.buttonNumber {
  margin-right: 8px;
}

.buttonsBox {
  display: flex;
  justify-content: center;
}

.startButton {
  margin-left: 19px;
  width: 151px;
}
