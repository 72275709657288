@import 'theme/common-style.scss';

.box {
  width: 100%;
  min-height: 54px;
  background-color: $color-input;
  padding: 11px 17px;
  border-radius: 27px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border: solid 1px $color-input;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover, &:focus-within {
    background-color: transparent;
    border: solid 1px $color-primary;
  }
}

.inputBox {
  flex-grow: 1;
  min-width: 10px;
  position: relative;
}

.input {
  @include font(15, 400);
  border: none;
  width: 100%;
  color: $color-font;
  line-height: 25px;
  background: none;
  position: relative;

  &::placeholder {
    color: $color-gray-dark;
  }
}

.dropdown {
  position: absolute;
  top: 30px;
  width: 250px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: $box-shadow;
  z-index: 1;
  padding: 10px 0;
}

.suggestedLabels {
  width: 100%;
  max-height: 200px;
  @include vertical-scrollbar();
}

.suggestedLabelsItem {
  cursor: pointer;
  padding: 5px 15px 5px 19px;

  &:hover {
    background-color: rgba($color-gray-light, 0.2);
  }
}
