@import 'theme/common-style.scss';

$space-between: 10px;
$space-between-tags: 7px;

.root {
  max-width: 100%;
  position: relative;
  z-index: 1;

  .repostBtn {
    @include gray-btn();
  }
}

.selected {
  box-shadow: 0 0 0 1px rgba($color-primary, 0.7);

  .repostBtn {
    background: $color-primary-gradient;
    color: $color-white;

    svg path {
      fill: $color-white;
    }
  }
}

.content {
  @include card(15px 25px);
  z-index: 2;
  height: 100%;
}

.filters {
  flex: 1;
}

.areThereFilters {
  margin-bottom: 15px;
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  row-gap: $space-between-tags;
  overflow: hidden;
}

.tagBox {
  position: relative;
  margin-right: $space-between-tags;
}
