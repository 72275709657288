@import 'theme/common-style.scss';

.field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputBox {
  @include input-box();
}

.input {
  border: none;
  font-size: 15px;
  color: $color-font;
  line-height: 24px;
  width: 100%;
  background: none;

  &::placeholder {
    color: $color-gray-dark;
  }
}

.inputSearchBox {
  background-color: $color-white;
  height: 34px;
  padding-right: 11px;

  &:hover,
  &:focus-within {
    background-color: $color-white;
  }

  input {
    font-size: 13px;
  }
}

.label {
  @include font(14, 600);
  color: $color-label;
  margin-bottom: 6px;
}

.secondaryLabel {
  @include font(13, 400);
  padding-left: 18px;
}

.hiddenLabel {
  display: none;
}

.errorMessage {
  @include font(13, 400);
  margin-top: 6px;
  color: $color-error;
}

.error {
  .inputBox {
    border-color: $color-error;
  }
}

.required {
  @include required;
}
