@import 'theme/common-style.scss';

.wrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  top: 44px;
  width: 100%;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: $box-shadow;
  z-index: 1;
  padding: 4px 0;
}

.hoistedDropdown {
  top: unset;
  bottom: 44px;
}

.option {
  @include font(16, medium);
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 4px;
  line-height: 17px;

  &:hover {
    background-color: #f2fbfe;
  }

  &.selected {
    background-color: #dff5fd !important;
    border-radius: 6px;
  }
}

.button {
  padding: 5px 15px 5px 19px;
  background-color: #e3e7f1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 34px;
  transition: all 0.2s ease-in-out;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
  }

  .iconBox {
    margin-right: 10px;
    display: flex;
    justify-content: center;
  }

  svg {
    path {
      fill: $color-white;
    }
  }

  &.active {
    color: $color-white;
    background: $color-primary-gradient;
    background-color: $color-primary-light;
  }

  &.open {
    color: $color-white;
    background-color: $color-primary-light;
  }

  .text {
    @include font(14, 500);
    margin-right: 11px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconOpen {
    transform: rotate(0);

    &.iconClose {
      transform: rotate(180deg);
    }
  }
}
