@import 'theme/common-style.scss';

.root {
  @include font(12);
  @include flex-container(center, center);
  text-align: center;
  position: absolute;
  bottom: calc(100% + 14px);
  left: 50%;
  min-height: 33px;
  background-color: $color-navy;
  color: $color-white;
  font-weight: $font-weight-bold;
  padding: 5px 13px;
  border-radius: 8px;
  box-shadow: 0 10px 24px rgba(29, 42, 68, 0.12);
  white-space: nowrap;
  transform: translateX(-50%);
  z-index: 9;
}

.left {
  bottom: revert;
  top: 50%;
  left: revert;
  right: calc(100% + 14px);
  transform: translate(0, -50%);
}

.bottom {
  bottom: revert;
  top: calc(100% + 14px);
  left: 50%;
}

.wrap {
  white-space: normal;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7.5px 0 7.5px;
  border-color: $color-navy transparent transparent transparent;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);

  &Left {
    bottom: revert;
    top: 50%;
    left: 100%;
    transform: translate(-25%, -50%) rotate(-90deg);
    transform-origin: center center;
  }

  &Bottom {
    bottom: 100%;
    top: revert;
    left: 50%;
    transform: translate(-25%, 0) rotate(-180deg);
    transform-origin: center center;
  }
}
