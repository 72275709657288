@import 'theme/common-style.scss';

.multiselectWrapper {
  position: relative;
  height: fit-content;

  .filter {
    padding: 5px 15px 5px 19px;
    background-color: #E3E7F8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 34px;
    transition: all 0.2s ease-in-out;

    .title {
      display: flex;
      align-items: center;
    }

    .iconBox {
      margin-left: -2px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
    }

    svg {
      path {
        fill: $color-gray-cool;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.useFullWidthForButton {
      width: 100%;
    }

    &.withItemsChecked {
      color: $color-white;
      background: $color-primary-gradient;
      background-color: $color-primary-light;

      .iconBox {
        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }

    &.open {
      color: $color-white;
      background-color: $color-primary-light;

      svg {
        path {
          fill: $color-white;
        }
      }
    }

    .filterName {
      @include font(14, medium);
      margin-right: 11px;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filterNumber {
      @include font(13, bold);
      color: $color-primary;
      background-color: #7fc6e9;
      border-radius: 12px;
      padding: 0 8px;
      margin-left: -4px;
      margin-right: 8px;
    }

    .iconClose {
      display: flex;
      border-radius: 50%;
      height: fit-content;
      padding: 2px;
      margin: -2px;

      &:hover {
        background-color: $color-dark;
      }

      svg {
        path {
          fill: $color-white;
        }
      }

    }

    .iconAdd {
      transform: rotate(45deg);
    }

    .iconOpen {
      transform: rotate(180deg);
    }
  }
}
