//TODO: replace with local fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/Inter-Regular.woff2') format('woff2'), url('./fonts/Inter-Regular.woff') format('woff'),
    url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/Inter-Medium.woff2') format('woff2'), url('./fonts/Inter-Medium.woff') format('woff'),
    url('./fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/Inter-SemiBold.woff2') format('woff2'), url('./fonts/Inter-SemiBold.woff') format('woff'),
    url('./fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

$fonts: (
  '': 16px,
  '8': 8px,
  '10': 10px,
  '11': 11px,
  '12': 12px,
  '13': 13px,
  '14': 14px,
  '15': 15px,
  '16': 16px,
  '17': 17px,
  '18': 18px,
  '20': 20px,
  '22': 22px,
  '24': 24px,
  '26': 26px,
  '28': 28px,
  '32': 32px,
  '36': 36px,
  '40': 40px,
  '44': 44px,
);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

@mixin font($font, $weight: $font-weight-normal) {
  font-size: map-get($fonts, '#{$font}');

  @if $weight == bold {
    font-weight: $font-weight-bold;
  } @else if $weight == medium {
    font-weight: $font-weight-medium;
  } @else {
    font-weight: $weight;
  }
}

@mixin table-cell-title {
  @include font(16, medium);
  color: $color-text;
  word-break: break-word;

  @include screen-xl-minus {
    @include font(12, medium);
  }
}

@mixin table-cell-title-ellipsis($max-width: none) {
  @include table-cell-title;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin table-cell-link {
  @include font(14);
  color: $color-gray-cool;
  word-break: break-word;

  @include screen-xl-minus {
    @include font(11);
  }
}

@mixin table-cell-parent {
  @include font(14, medium);
  color: $color-gray-cool;
  word-break: break-word;

  @include screen-xl-minus {
    @include font(11, medium);
  }
}

@mixin typography {
  h1,
  .largeP {
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2.215rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h4 {
    font-weight: 400;
    font-size: 1rem;
  }

  h6,
  .smallH {
    font-weight: 700;
    font-size: 0.625rem;

    &_uppercase {
      @extend .smallH;
      text-transform: uppercase;
    }
  }

  p {
    font-weight: lighter;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  a {
    color: initial;
    font-family: 'Inter', sans-serif;
    text-decoration: initial;
  }
}

@mixin menu-link {
  @include flex-container(flex-start, center);
  color: $color-text;
}
