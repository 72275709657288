@import 'theme/common-style.scss';

.title {
  @include font(28, 300);
  line-height: 25px;
  margin-bottom: 22px;
}

.submitBtnContainer {
  @include flex-container('center', 'center');
  margin-top: 1rem;

  &Confusing {
    background-color: red;
  }
}
