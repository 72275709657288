@import 'theme/common-style.scss';

.root {
  padding-bottom: $misclassifications-action-bar-height;
  position: relative;
}

.logo {
  width: 77px;
  position: absolute;
  top: 16px;
  left: 16px;

  path {
    fill: $color-dark;
  }
}

.infoIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.messageWrapper {
  @include flex-container(center, center);
  width: 100vw;
  height: calc(100vh - $misclassifications-action-bar-height);
}

.iconBox {
  @include flex-container('center', 'center');
  background-color: rgba($color-white, 0.63);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin-right: 22px;
}

.message {
  @include flex-container(flex-start, center);
  max-width: 515px;
  width: fit-content;
  height: 200px;
  padding: 16px 45px 16px 40px;
  font-size: 22px;
  font-weight: 600;

  &.error {
    color: $color-error-alt;
    background-color: $color-error-alt-light;

    .iconBox {
      path:last-of-type {
        fill: $color-error-alt;
      }
    }
  }

  &.success {
    color: $color-success-alt;
    background-color: $color-success-alt-light;
  }
}

.actionBar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 36px;
  height: $misclassifications-action-bar-height;
  background-color: $color-white;
  border-top: 1px solid $color-gray-border-alt;
  z-index: 3;

  &Content {
    @include flex-container(space-between, center);
    padding: 0 24px;
  }
}

.container {
  max-width: 1324px;
  margin: 0 auto;
  height: inherit;

  @include screen-xl-minus {
    max-width: 1100px;
  }
}

.loadingArea {
  @include flex-container(center, center);
  width: 100vw;
  height: 100vh;
}

.postCountBox {
  position: relative;
}

.title {
  font-weight: $font-weight-bold;
  color: $color-text;
}

.main {
  @include flex-container(flex-start, flex-start);
  margin: 0 auto;
  padding-top: 28px;
}

.card {
  @include card();
  flex: 1;
  min-height: calc(100vh - 67px - 28px - 15px);
}

.buttons {
  @include flex-container();

  button ~ button {
    margin-left: 29px;
  }
}

.isConfusing {
  background-color: $color-orange;
  color: $color-white;

  svg path {
    fill: $color-white;
  }
}

.markAsCheckedBtn {
  width: 208px;
}
