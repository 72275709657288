@import 'theme/common-style.scss';

.root {
  @include flex-container(flex-start, center);
  cursor: pointer;
}

.disabled {
  cursor: auto;
  opacity: 0.8;
}

.checkbox {
  @include flex-container(center, center);
  width: 20px;
  height: 20px;
  background-color: $color-white;
  border-radius: 5px;
  border-top: 1px solid $color-gray-border-alt;
  margin-right: 17px;
}

.checked {
  background-color: $color-gray-cool;
}

.label {
  @include font(15);
  color: $color-gray-cool;

  &Checked {
    font-weight: 700;
  }
}
