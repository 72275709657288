@import 'theme/common-style.scss';

.root {
  @include common-btn;
  @include font(15, medium);
  padding: 11px 25px 11px 20px;
}

.default {
  background: $color-primary-gradient;
}

.error {
  color: $color-error-alt;
  background: transparent;
}

.warning {
  color: $color-orange;
  background: transparent;
}
