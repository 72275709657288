@import 'theme/common-style.scss';

.root {
  position: relative;
  margin-bottom: 10px;
}

.label {
  display: block;
}

.selectedCategoriesBox {
  @include input-box;
  @include flex-container(flex-start, center, wrap);
  position: relative;
  min-height: 40px;
  padding: 6px 36px 6px 10px;
}

.categoryLabel {
  @include font(13, $font-weight-medium);
  padding: 2px 14px;
  border-radius: 10px;
  margin: 2px;
  background: $color-primary-gradient;
  color: $color-white;
  transition: opacity .3s ease;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}
