@import 'theme/common-style.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.headerContent {
  display: flex;
  align-items: center;
}

.logo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 999px;
  margin-right: 8px;
}

.serviceName {
  font-size: 16px;
  font-weight: 700;
  color: $color-font;
}

.postInformationBox {
  display: flex;
  align-items: center;

  p {
    font-size: 13px;
    font-weight: 400;
    color: $color-font-secondary;
  }
}

.dot {
  width: 2px;
  height: 2px;
  border-radius: 999px;
  background: $color-font-secondary;
  margin: 0 6px;
}

.rssLogo {
  width: 24px;
}
