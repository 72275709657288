@import 'theme/common-style.scss';

.breadcrumbs {
  @include flex-container(flex-start, center);
  margin-bottom: 34px;

  svg {
    margin-left: 6.5px;
    margin-right: 6.5px;
  }
}

.container {
  @include flex-container(flex-start, center);

  > div {
    @include flex-container(flex-start, center);
  }
}

.ancestor {
  @include font(15);
  color: $color-gray-cool;
};

.current {
  @include font(15, medium);
  color: #64748B;
}