@import 'theme/common-style';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include buttons;
  @include datepicker;
  @include typography;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: $color-font;
  background-color: $color-background;
}

body.locked {
  overflow: hidden !important;
  position: relative !important;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input {
  border: none;
  outline: none;
}

table {
  @include table;
}
