@import 'theme/common-style.scss';

.tag {
  @include font(15);
  @include flex-container(center, center);
  font-weight: $font-weight-medium;
  width: fit-content;
  background-color: $color-black;
  color: $color-white;
  padding: 0 14px 0 12px;
  height: 25px;
  border-radius: 13px;

  &.isClickable {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .customIconBox {
    margin-right: 7px;
    display: flex;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: $color-white;
      }
    }
  }

  .text {
    @include font(14, 500);
    white-space: nowrap;
    line-height: 21px;
  }

  .subText {
    @include font(14, 500);
    white-space: nowrap;
    line-height: 21px;
    opacity: 0.5;
    margin-left: 5px;
  }

  .closeBtn {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }

  .closeIcon {
    width: 13px;
    height: 13px;

    path {
      fill: $color-white;
    }
  }
}
