
@import 'theme/common-style.scss';

.root {
  position: relative;
}

.dropdown {
  position: absolute;
  right: 0;
  background-color: $color-white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  z-index: 9;

  ul li > div {
    padding: 8px 16px;

    &:hover {
      background-color: $color-table-background;
    }
  }
}