@import 'theme/common-style.scss';

.table {
  @include card(15px 25px 23px 25px);
  margin-top: -17px;
}

.annotatorTable {
  @include annotator-table($color-gray-table-cell-alt);
}

.articlesTableHeader {
  margin-top: 17px;
}

.articlesTable {
  td {
    &[data-th='Id'] {
      width: 25%;
    }
    &[data-th='Name'] {
      width: 30%;
    }
    &[data-th='Status'] {
      width: 10%;
    }
  }
}

.statusLabel {
  @include font(15, 500);
  @include flex-container(flex-start, center, row nowrap, true);
  height: 25px;
  padding: 0 14px;
  border-radius: 13px;

  &-todo {
    background-color: $color-gray-table-cell-alt;
    color: $color-font-secondary;
  }
  &-done {
    background-color: $color-success-alt;
    color: $color-white;
  }
  &-confusing {
    background-color: $color-orange;
    color: $color-white;
  }
  &-spam {
    background-color: $color-error-alt;
    color: $color-white;
  }
}
