@import 'theme/common-style.scss';

.main {
  @include card(23px 25px);
  @include annotator-table();
  margin-top: 17px;
}

.buttonsContainer {
  display: flex;
}
