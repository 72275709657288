@import 'theme/common-style.scss';

.root {
  @include card(18px 36px);
  @include flex-container(space-between, center);
  height: 76px;

  h1,
  h2 {
    font-weight: $font-weight-bold;
    color: $color-text;
  }

  .addProfileBtn {
    width: 246px;
  }
}
