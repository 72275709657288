@import 'theme/common-style.scss';

.root {
  @include flex-container(space-between, center);
  width: 100%;
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
}

.iconContainer {
  @include flex-container(flex-start, center);
  position: relative;

  > span {
    @include screen-xl-minus {
      @include font(12);
    }
  }
}

.alignedLeft {
  justify-content: flex-start;
  width: auto;
}

.copyLabel {
  @include font(10, medium);
  position: absolute;
  transform: translateX(-50%);
  color: $color-white;
  background: $color-black;
  border-radius: 13px;
  padding: 2px 13px;
  white-space: nowrap;
  top: -24px;
  left: 0;
}
