@import './common-button.scss';
@import './common-colors.scss';
@import './common-datepicker.scss';
@import './common-flex.scss';
@import './common-font.scss';
@import './common-responsive.scss';
@import './common-scrollbar.scss';
@import './common-table.scss';

$topbar-height: 71px;

$feed-content-width: 65%;
$feed-content-width-xl-minus: 85%;

$misclassifications-action-bar-height: 67px;

@mixin feed-content-width {
  width: $feed-content-width;

  @include screen-xl-minus {
    width: $feed-content-width-xl-minus;
  }
}

@mixin card($padding: 24px) {
  padding: $padding;
  background-color: $color-white;
}

@mixin input-box($background-color: $color-input) {
  @include flex-container(space-between, center);
  width: 100%;
  background-color: $background-color;
  padding: 7px 20px;
  border-radius: 17px;
  border: 1px solid $color-gray-border-alt;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover,
  &:focus-within {
    background-color: transparent;
    border: solid 1px $color-primary;
  }
}

@mixin handle-tooltip {
  cursor: pointer;

  > div {
    display: none;
  }

  &:hover > div {
    display: flex;
  }
}

@mixin required {
  position: relative;

  &::after {
    content: '*';
    position: absolute;
    right: -11px;
    top: -5px;
    color: $color-primary-reversed;
  }
}