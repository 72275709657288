@import 'theme/common-style.scss';

.root {
  @include flex-container();
}

.logo {
  width: 77px;
  position: absolute;
  top: 16px;
  left: 16px;

  path {
    fill: $color-dark;
  }
}
