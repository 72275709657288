@import 'theme/common-style.scss';

$panel-height: calc(100vh - #{$misclassifications-action-bar-height} - 28px - 15px);
$other-elements-height: calc(48px + 24px + 14px + 54.5px + 13px); // panel top+bottom padding, header height, header margin bottom, search box height, extra offset

.root {
  @include flex-container();
  flex-direction: column;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  margin-left: 16px;
  width: 519px;
  height: $panel-height;
}

.header {
  @include flex-container(space-between, center);
  margin-bottom: 14px;
}

.legend {
  @include font(12, 400);
  @include flex-container();
  color: $color-gray-cool;
  margin-left: 36px;

  span {
    white-space: nowrap;
  }

  &Item {
    @include flex-container(flex-start, center);
    @include handle-tooltip;
    position: relative;

    svg {
      margin-right: 8px;
    }

    &~& {
      margin-left: 17px;

      @include screen-xl-minus {
        margin-left: 12px;
      }
    }
  }
}

.title {
  @include font(20, $font-weight-bold);
  line-height: 24px;
  color: $color-text;
}

.card {
  @include card(24px 30px);
}

.metadataContainer {
  @include flex-container();
  flex: 1;
  border: 1px solid $color-gray-border-alt;
}

.label {
  @include flex-container(flex-start, center);
  @include font(15, medium);
  flex: 1;
  position: relative;
  color: $color-gray-cool;
  padding: 16px;
  cursor: pointer;
  transition: background-color .3s ease;

  svg {
    margin-right: 12px;

    path {
      fill: $color-gray-cool;
    }
  }

  &Active {
    background-color: $color-gray-light-alt;

    &::after {
      content: '';
      width: 1px;
      position: absolute;
      top: 1px;
      right: -1px;
      bottom: 1px;
      background-color: $color-gray-light-alt;
    }
  }
}

.labelSelection {
  width: 175px;
  border-right: 1px solid $color-gray-border-alt;
}

.labelContainer {
  @include flex-container(flex-start, center);
  position: relative;
}

.interactionStatus {
  @include handle-tooltip;
  display: flex;
  position: relative;
  margin-right: 16px;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.viewed {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M10 11.25c-.69 0-1.25-.56-1.25-1.25S9.31 8.75 10 8.75s1.25.56 1.25 1.25-.56 1.25-1.25 1.25m0-4.167A2.92 2.92 0 0 0 7.083 10 2.92 2.92 0 0 0 10 12.917 2.92 2.92 0 0 0 12.917 10 2.92 2.92 0 0 0 10 7.083m.183 7.082c-3.588.083-5.93-2.986-6.706-4.169.855-1.337 3.009-4.076 6.34-4.16 3.574-.091 5.929 2.985 6.705 4.168-.854 1.337-3.008 4.076-6.339 4.16m8.04-4.579c-.532-.926-3.468-5.571-8.448-5.415-4.607.116-7.286 4.175-7.998 5.415a.832.832 0 0 0 0 .83c.524.914 3.358 5.418 8.244 5.418.068 0 .136 0 .205-.002 4.605-.118 7.285-4.175 7.997-5.416a.836.836 0 0 0 0-.83' id='a'/%3E%3C/defs%3E%3Cuse fill='%2300aeef' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/svg%3E");
}

.edited {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h20v20H0z'/%3E%3Cpath fill='%2344AF69' fill-rule='nonzero' d='M7.5 13.5 4 10l-1.167 1.167L7.5 15.833l10-10-1.167-1.166z'/%3E%3C/g%3E%3C/svg%3E");
}

.todo {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 14.5a.813.813 0 0 0 .597-.246.813.813 0 0 0 .247-.598.813.813 0 0 0-.247-.597.813.813 0 0 0-.597-.246.813.813 0 0 0-.597.246.813.813 0 0 0-.247.597c0 .234.082.433.247.598A.813.813 0 0 0 10 14.5Zm-.844-3.375h1.688V5.5H9.156v5.625ZM10 19a8.769 8.769 0 0 1-3.492-.703 9.074 9.074 0 0 1-2.871-1.934 9.074 9.074 0 0 1-1.934-2.871A8.769 8.769 0 0 1 1 10c0-1.25.234-2.418.703-3.504a9.074 9.074 0 0 1 4.805-4.793A8.769 8.769 0 0 1 10 1c1.25 0 2.418.234 3.504.703a9.113 9.113 0 0 1 4.793 4.793A8.747 8.747 0 0 1 19 10a8.769 8.769 0 0 1-.703 3.492 9.074 9.074 0 0 1-4.793 4.805A8.747 8.747 0 0 1 10 19Z' fill='%23FF7700' fill-rule='nonzero'/%3E%3C/svg%3E");
}

.labelValueColumn {
  flex: 1;
  background-color: $color-gray-light-alt;
}

.metadataList {
  border: 1px solid $color-gray-border-alt;
}

.searchContainer {
  @include flex-container(center, center);
  height: 54.5px;
  border-bottom: 1px solid $color-gray-border-alt;
}

.innerContainer {
  padding: 0 21px;
}

.panelRows {
  @include flex-container(flex-start, stretch, column nowrap);
  @include vertical-scrollbar(
    $background-color: transparent,
    $width: 5px,
    $border-width: 10px,
    $thumb-color: $color-misclassifications-scrollbar,
    $track-color: transparent
  );
  overflow-y: auto;
  padding: 21.5px 20px;
  margin: 5px 3px 5px 0;

  &Selected {
    max-height: 350px;

    @include screen-xl-minus {
      max-height: 250px;
    }
  }

  &ToSelect {
    height: calc(#{$panel-height} - #{$other-elements-height} - #{var(--selected-checkboxes-container-height)});
    padding: 21.5px 20px;
  }

  &.showBorderBottom {
    border-bottom: 1px solid $color-gray-border-alt;
  }

  &.noSelected {
    padding: 0;
    border-bottom: 0;
  }

  > div ~ div {
    margin-top: 16px;
  }
}

.empty {
  @include font(13);
  color: $color-gray-cool;
  text-align: center;
}

.loadMoreBtnContainer {
  @include flex-container(center, center);
  max-height: 80px;
}

.checkboxSelected {
  display: none !important;
}
