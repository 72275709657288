@import 'theme/common-style.scss';

.twitterPost {
  margin: 0 auto;
  max-width: 550px;
}

.skeletonsBox {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.hideTweet {
  visibility: hidden;
  height: 0;
}
