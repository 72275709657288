@import 'theme/common-style.scss';

.root {
  display: flex;
  flex-direction: column;
}

.field ~ .field {
  margin-top: 20px;
}

.fieldContainer {
  display: flex;
}

.footer {
  @include flex-container(space-between);
  margin-top: 47px;
}

.submitBtn {
  width: 86px;
}

.submitBtnText {
  &.loading {
    opacity: 0;
  }
}

.importFromCSV {
  &Container {
    position: relative;
    width: 144px;
    margin-right: 1rem;
  }

  &Title {
    @include font(14, $font-weight-bold);
    @include required;
    display: inline-block;
    color: $color-label;
    margin-bottom: 6px;
    pointer-events: all;
  }

  &Component {
    position: absolute;
    width: 100%;

    input[type="file"] {
      visibility: hidden;
    }
  }

  &FileName {
    @include flex-container(flex-start, center);
  }
}

.loadingArea {
  @include flex-container(center, center);
  min-height: 400px;
}

.availableCategories {
  button {
    margin-right: 6px;
    margin-bottom: 6px;

    ~ button {
      margin-left: 0;
    }
  }

  &Label {
    display: block;
    padding-left: 0;
  }
}

