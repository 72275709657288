@import 'theme/common-style.scss';

.posts {
  display: block;
  position: relative;

  .errorNoDataLoaded {
    @include font(18, medium);
    width: 430px;
    margin: 100px auto 0;
    text-align: center;

    span {
      color: $color-primary;
      cursor: pointer;
    }

    span:hover {
      border-bottom: 1px solid $color-primary;
    }
  }
}

.spinnerAboveOtherContent {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 1em;

  .noResults {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    @include font(24, medium);
  }
}

.spinnerBox {
  padding-top: 2em;
}
