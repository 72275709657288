@import 'theme/common-style.scss';

.rssPost {
  border: 1px solid #d8dbe5;
  padding: 15px;
  border-radius: 12px;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  color: $color-font;
  margin-top: 20px;
}

.message {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: $color-font;
}

.readMoreBtn {
  font-size: 16px;
  color: $color-primary;
  font-weight: 700;
}

.photo {
  width: 100%;
  height: 285px;
  background-size: cover;
  border-radius: 12px;
}

.audio {
  width: 100%;
}
