@import 'theme/common-style.scss';

.navigation {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 628px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  box-shadow: $box-shadow;
  background-color: $color-white;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  padding: 12px 40px 12px 20px;
  font-weight: 700;
  z-index: 2;
}

.active {
  display: flex;
}

.keyItem {
  display: flex;
  align-items: center;

  p {
    color: $color-dark;
  }
}

.key {
  width: 30px;
  height: 30px;
  padding: 7px 11px;
  border-radius: 8px;
  background-color: $color-gray-light;
  font-size: 14px;
  color: $color-dark !important;
  margin-right: 11px;
  font-weight: 700;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.shortkeysText {
  font-size: 13px;
  color: $color-gray;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.closeIconBox {
  height: 100%;
}
