@import 'theme/common-style.scss';

.iconBox {
  display: flex;
  align-items: center;
}

.startIconBox {
  margin-right: 6px;
}

.endIconBox {
  margin-left: 6px;
}
