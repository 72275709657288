@mixin table {
  border: 1px solid $color-gray-border-alt;
  border-collapse: collapse;
  width: 100%;

  th {
    @include font(14, medium);
    position: relative;
    padding: 16.5px 16.5px 16.5px 20px;
    color: $color-gray-cool;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid $color-gray-border-alt;

    @include screen-xl-minus {
      @include font(12, medium);
      padding: 16.5px 12px 16.5px 16px;
    }

    &.sortable {
      cursor: pointer;

      &:before {
        position: absolute;
        font-size: 6px;
        left: 10px;
        top: 50%;
        transform-origin: top center;

        @include screen-xl-minus {
          font-size: 5px;
          left: 8px;
        }
      }
    }

    &.asc:before {
      content: '\25E4';
      transform: translateY(0%) rotate(45deg);
    }

    &.desc:before {
      content: '\25E2';
      transform: translateY(-30%) rotate(45deg);
    }
  }

  td {
    font-size: 15px;
    color: $color-gray-table-cell;
    padding: 23px 20px;

    ~ td {
      border-left: 2px solid $color-white;
    }

    @include screen-xl-minus {
      font-size: 13px;
      padding: 23px 12px;
    }
  }

  tr:nth-child(odd) td {
    background-color: $color-table-background;
  }
}

@mixin annotator-table($cell-bg-color: $color-table-background) {
  tr:nth-child(odd) td {
    background-color: $cell-bg-color;
  }

  td {
    &[data-th="Name"] {
      width: 40%;

      @include screen-xl-minus {
        width: 35%;
      }
    }
    &[data-th="Assigned"] {
      width: 8.5%;
    }
    &[data-th="Results"] {
      width: 12.5%;
    }
    &[data-th="Categories"] {
      & > div {
        @include flex-container();
        flex-wrap: wrap;
        margin: -5px;

        & > div {
          margin: 2.5px;
        }
      }
    }
    &:last-child {
      width: 12.5%;

      @include screen-xl-minus {
        width: 15%;
      }

      > div {
        @include flex-container(center);
        font-weight: $font-weight-medium;

        > * ~ * {
          margin-left: 28px;

          @include screen-xl-minus {
            margin-left: 18px;
          }
        }
      }

      a {
        color: $color-label;
        white-space: nowrap;
      }
    }
  }
}