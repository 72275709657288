@import 'theme/common-style.scss';

.feedFilters {
  @include flex-container(space-between);
  @include card(25px);
  position: sticky;
  top: -1px;
  z-index: 2;
  margin-bottom: 17px;

  .filterWrapper {
    position: relative;
    margin-right: 13px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.modalTitle {
  font-size: 28px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 22px;
}

.feedFilters__lists {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  column-gap: 13px;
  row-gap: 13px;
}

.filtersListsInModal {
  height: 340px;
}

.feedFilters__moreBtn {
  @include flex-container(space-between, center);
  padding: 5px 15px 5px 19px;
  background-color: #e3e7f1;
  border-radius: 8px;
  cursor: pointer;
  height: 34px;
  transition: all 0.2s ease-in-out;

  &.withItemsChecked {
    color: $color-white;
    background: $color-primary-gradient;
    background-color: $color-primary-light;

    .moreBtnIcon {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}

.moreBtnText {
  @include font(14, bold);
  margin-right: 11px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreBtnIcon {
  transform: rotate(45deg);
}
