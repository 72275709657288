$color-primary-light: #00aeef;
$color-primary: #0083ca;
$color-primary-reversed: #ff1e1e;
$color-secondary: #d4dae8;
$color-dark: #333333;
$color-dark-alt: #202123;
$color-metallic-gold: #fef5d0;
$color-metallic-gold-alt: #cdaf33;

$color-orange: #ff7700;
$color-orange-light: #ffe5cd;

$color-yellow: #ffff00;

$color-background: #f3f5fa;
$color-table-background: #f1f4f9;

$color-primary-gradient: linear-gradient(90deg, $color-primary-light 0%, $color-primary 100%);

$color-blue-highlight: #d9edf7;
$color-gray-light: #d4dae8;
$color-gray-light-alt: #f1f3f9;
$color-gray-light-alt2: #f7f8fb;
$color-gray: #babfcb;
$color-gray-dark: #9fa5b3;
$color-gray-border: #d8dbe5;
$color-gray-border-alt: #e0e4e9;
$color-gray-cool: #697094;
$color-gray-table-cell: #64748b;
$color-gray-table-cell-alt: #dadfec;
$color-gray-glaucous: #e3e7f8;
$color-gray-skeleton: #dddbdd;
$color-misclassifications-scrollbar: #8288a6;

$color-input: #eaedf4;

$color-black: #000000;
$color-white: #ffffff;

$color-font: $color-dark;
$color-font-secondary: #6c7489;

$color-text: #091356;
$color-label: #64748b;

$color-success: #4bb543;
$color-success-alt: #44af69;
$color-success-alt-light: #daefe1;
$color-error: #ff6149;
$color-error-alt: #ff0a58;
$color-error-alt-light: #ffcede;

$color-navy: #1d2a44;

$color-datepicker-day-name: #9fa5b3;
$color-datepicker-day-name-selected: #838aa7;
$color-datepicker-day-outside-month-bg: $color-gray-light-alt;
$color-datepicker-day-outside-month-text: #bac2c7;
$color-datepicker-close-icon: #216ba5;

$box-shadow: 0 2px 25px 0 rgba(58, 82, 129, 0.21);
$color-datepicker-day-name-selected-box-shadow: 0 2px 6px 0 rgba(171, 171, 171, 0.5);
