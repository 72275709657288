@import 'theme/common-style.scss';

.root {
  @include flex-container();
  position: relative;
  width: var(--progress-bar-width);
  background-color: $color-gray-table-cell-alt;
}

.lightTrack {
  background-color: $color-gray-light-alt;
}

.bar {
  height: 10px;
  border-radius: 7px;
}

.countBar {
  position: absolute;
  cursor: pointer;

  &Inner {
    @include handle-tooltip;
    position: absolute;
    right: 0;
    height: inherit;
  }
}

.done {
  background-color: $color-success-alt;
}
.confusing {
  background-color: $color-orange;
}
.spam {
  background-color: $color-error-alt;
}