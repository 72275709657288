@import 'theme/common-style.scss';

.root {
  @include flex-container(flex-start, center);

  .forceNewLine {
    display: block;
  }
}

.searchListElement {
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: $color-gray-glaucous;
  }
}

.imgContainer {
  margin-right: 20px;

  @include screen-xl-minus {
    margin-right: 12px;
  }
}

.imgCircle {
  @include flex-container(center, center);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid $color-gray-border;
}

.fallbackAvatar {
  background-color: $color-gray-glaucous;
}

.feedName {
  @include table-cell-title-ellipsis(300px);
  display: inline-block;

  @include screen-xl-minus {
    @include table-cell-title-ellipsis(220px);
  }

  &Small {
    @include font(12);
  }
}

.parentLink {
  @include table-cell-title-ellipsis(300px);
  display: inline-block;

  @include screen-xl-minus {
    @include table-cell-title-ellipsis(180px);
  }
}

.twitterName {
  @include font(14);
  color: $color-gray-cool;

  &Small {
    @include font(10);
  }
}

.sourceKindContainer {
  @include flex-container(flex-start, center);

  svg {
    width: 20px;
    height: 13px;
    margin-right: 4px;
  }

  &Small {
    margin-top: 0;
  }
}

.parentLink {
  @include table-cell-parent;

  &Row {
    margin-top: 8px;
    background-color: $color-gray-table-cell-alt;
    display: flex;
    height: 22px;
    align-items: center;
  }
}

.sourceContainer {
  display: flex;
  @include table-cell-link;
}

.feedNameAndSourceContainer {
  flex-grow: 1;
}

.copyToClipboardCell {
  width: 100%;
}

.enterArrow {
  margin: 0 6px 0 4px;
}

.infoLabel {
  @include font(11, medium);
  display: flex;
  align-items: center;
  color: $color-white;
  height: 18px;
  padding: 0px 8px;
  border-radius: 4px;

  &Error {
    background-color: $color-error-alt;
  }

  &Warning {
    background-color: $color-orange;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
      margin-left: 3px;
      transform: scale(.8);
    }

    &:hover {
      background-color: lighten($color-orange, 10%);
    }
  }
}

.infoLabelsContainer {
  @include flex-container(flex-start, center);
  gap: 6px;
  margin-top: 8px;
}
