@import 'theme/common-style.scss';

.loginForm {
  display: flex;
  flex-direction: column;
}

.passwordInput {
  margin-top: 8px;
}

.rememberMeCheckbox {
  margin-top: 25px;
}

.submitBtn {
  margin-top: 25px;
  background: $color-primary-gradient;
  padding: 11px 55px;
  font-size: 16px;
  font-weight: 500;
  color: $color-white;
  text-align: center;
  border-radius: 20px;
  align-self: center;
  position: relative;
}

.submitBtnText {
  &.loading {
    opacity: 0;
  }
}

.errorMessage {
  @include font(13, 400);
  color: $color-error;
  margin-top: 12px;
}
