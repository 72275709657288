@import 'theme/common-style.scss';

.inner {
  @include feed-content-width;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: calc(34px + 22.5px);
}
