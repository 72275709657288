@import 'theme/common-style.scss';

.root {
  @include flex-container(flex-start, flex-start, column);
}

.searchListElement {
  padding: 6px;
  border-radius: 6px;
  transition: background-color .3s ease;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: $color-gray-glaucous;
  }
}

.imgContainer {
  margin-right: 20px;

  @include screen-xl-minus {
    margin-right: 12px;
  }
}

.imgCircle {
  @include flex-container(center, center);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid $color-gray-border;
}

.fallbackAvatar {
  background-color: $color-gray-glaucous;
}

.name {
  @include table-cell-title;

  &Small {
    @include font(12);
  }
}

.twitterName {
  @include font(14);
  color: $color-gray-cool;

  &Small {
    @include font(10);
  }
}

.sourceKindContainer {
  @include flex-container(flex-start, center);
  margin-top: 5px;

  svg {
    width: 13px;
    height: 13px;
    margin-right: 8px;
  }

  &Small {
    margin-top: 0;
  }
}

