@import 'theme/common-style.scss';

.main {
  display: flex;
  align-items: center;
  color: $color-label;
  font-size: 15px;

  .select {
    margin-right: 22px;
  }

  .select > button {
    background: $color-white;
    border: 1px solid $color-secondary;
    border-radius: 17px;
    color: $color-text;
    margin-left: 4px;

    > :nth-child(2) svg path {
      fill: $color-label;
    }
  }
}
