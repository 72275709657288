@import 'theme/common-style.scss';

.filterItem {
  @include flex-container(flex-start, center);
  margin-bottom: 12px;

  .filterLabel {
    display: flex;

    .filterLabelName {
      @include font(13, medium);
      color: $color-dark;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &Wide {
        max-width: revert;
        overflow: revert;
        text-overflow: revert;
      }
    }
  }
}
