@import 'theme/common-style.scss';

.skeleton {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  opacity: 0.7;
  background-color: $color-gray-skeleton;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($color-white, 0) 0,
      rgba($color-white, 0.2) 20%,
      rgba($color-white, 0.5) 60%,
      rgba($color-white, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
