@import 'theme/common-style.scss';

.filterDropdown {
  position: absolute;
  right: 0;
  top: 44px;
  width: 400px;
  max-height: 347px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: $box-shadow;
  z-index: 1;
  padding-bottom: 12px;

  .searchBox {
    margin: 16px 15px 20px 16px;
  }

  .belowSearchBox {
    max-height: 241px;
    width: 100%;
    @include vertical-scrollbar();
  }

  .checkedItems {
    margin: 0 17px;
  }

  .recentTitle {
    @include font(13, bold);
    color: $color-gray;
    padding: 20px 17px 16px;
  }

  .recentItems {
    margin: 0 17px;
    position: relative;
  }

  .showFullList {
    @include font(16, bold);
    color: $color-primary;
    margin-top: 9px;
    padding: 12px 17px 0 17px;
    border-top: 1px solid $color-gray-border;
    width: 100%;
    text-align: start;
  }
}

.wide {
  width: 580px;
}

.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 17px 0 17px;

  .loader {
    path {
      fill: $color-secondary;
    }
  }
}

.itemsLoader {
  position: absolute;
  inset: 0;
  background-color: rgba($color-white, 0.5);
  z-index: 2;
}

.errorMessage {
  @include font(13, 400);
  color: $color-error;
  padding: 0px 17px 0 17px;
}
