@import 'theme/common-style.scss';

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba($color-gray-light, 0.49);
  z-index: 9;
  overflow: auto;
  animation: displayModal 150ms ease-in;
}

.modal {
  background: $color-white;
  margin: 60px auto;
  outline: none;
  padding: 45px 40px;
  width: 90%;
  border-radius: 8px;
  position: relative;
  box-shadow: $box-shadow;
}

.closeBtn {
  padding: 5px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  @include font(28, 300);
  margin-bottom: 28px;
}

@include screen-min($screen-lg-min) {
  .modal {
    width: 680px;
  }
}

@keyframes displayModal {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}