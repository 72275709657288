@import 'theme/common-style.scss';

.root {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
}

.toast {
  @include flex-container(flex-start, center);
  padding: 12px;
  border-radius: 8px;
  box-shadow: $box-shadow;
  min-width: 350px;
  color: $color-white;

  &~& {
    margin-top: 12px;
  }
}

.success {
  background-color: $color-success-alt;
}
.error {
  background-color: $color-error;
}
.warning {
  background-color: $color-orange;
}

.textContainer {
  @include font(14, $font-weight-medium);
  margin-left: 12px;
}