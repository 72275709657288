@import 'theme/common-style.scss';

.confirm {
  padding-top: 36px;

  p {
    text-align: center;
  }
}

.btnContainer {
  @include flex-container(space-around);
  margin-top: 48px;
}

.submitBtn {
  position: relative;

  &BtnText.loading {
    opacity: 0;
    visibility: 0;
  }
}
