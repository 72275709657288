@import 'theme/common-style.scss';

$sort-arrow-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / arrow / dropdown%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='arrow_drop_down-24px'%3E%3Cpolygon id='Path' points='0 0 20 0 20 20 0 20'%3E%3C/polygon%3E%3Cpolygon id='Path' fill='%23697094' fill-rule='nonzero' points='5.83333333 8.33333333 10 12.5 14.1666667 8.33333333'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

.profilesList {
  padding: 23px 25px;
}

.tableWrapper {
  overflow: auto;
  border: 1px solid $color-gray-border-alt;
  @include horizontal-scrollbar;
  padding: 0;
  margin: 0;

  table {
    table-layout: auto;
    width: 100%;
    border: none;

    thead tr th {
      position: sticky;
      top: 0;
    }

    th,
    td {
      background: $color-white;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }

    th:last-child,
    td:last-child {
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  th {
    @include font(14, medium);
    padding: 16.5px;
    color: $color-gray-cool;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    border-bottom: 1px solid $color-gray-border-alt;

    span {
      padding-left: 14px;
      position: relative;
    }

    @include screen-xl-minus {
      @include font(11, medium);
      padding: 16.5px 12px;
    }
  }

  td {
    font-size: 15px;
    color: $color-gray-table-cell;
    padding: 10px 20px;
    height: 84px;

    ~ td {
      border-left: 2px solid $color-white;
    }

    @include screen-xl-minus {
      font-size: 13px;
      padding: 7px 12px;
    }
  }

  tr:nth-child(odd) td {
    background-color: $color-table-background;
  }

  tr.highlighted td {
    background-color: $color-blue-highlight;
  }
}

.search {
  margin-top: 2px;
}

.postalCode {
  @include table-cell-title;
}

.coordinates {
  @include flex-container();
  @include font(10);
  flex-direction: column;

  > span {
    @include font(8);
    white-space: nowrap;
  }
}

.sortable {
  position: relative;

  > span {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -5px;
      width: 19px;
      height: 19px;
      transition: transform 0.3s ease;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / arrow / sort%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icon-/-arrow-/-sort' fill='%23697094'%3E%3Cpath d='M6.97802198,8.02205264 L10,5.00007461 L13.021978,8.02205264 L6.97802198,8.02205264 Z M6.97802198,11.9779474 L10,14.9999254 L13.021978,11.9779474 L6.97802198,11.9779474 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

      @include screen-xl-minus {
        top: -2px;
      }
    }
  }
}

.desc > span:before {
  background-image: $sort-arrow-image;
}

.asc > span:before {
  background-image: $sort-arrow-image;
  transform: rotate(180deg);
  transform-origin: center;
}

.flagsAndLabels {
  @include flex-container;
}

.zipCodeCell > div {
  width: 100px;
}

.dateCell {
  width: 175px;
}

.actionCell {
  width: 104px;

  span {
    display: none;
  }
}

.actionBtnCellContent {
  display: flex;

  > div ~ div {
    margin-left: 10px;
  }
}

.parentBox {
  cursor: pointer;
}

.actionBtn {
  display: flex;
  cursor: pointer;
}

.actionBtnIcon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 11px;
}

.actionBtnArchive {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / archive 2%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icon-/-archive-2' fill='%23697094' fill-rule='nonzero'%3E%3Cg id='inventory_2_FILL0_wght400_GRAD0_opsz20' transform='translate(1.500000, 1.500000)'%3E%3Cpath d='M1.0625,15.40625 L1.0625,6.2868125 C0.723208333,6.18339583 0.461125,5.9914375 0.27625,5.7109375 C0.0920833333,5.4304375 0,5.12054167 0,4.78125 L0,1.59375 C0,1.15104167 0.155125,0.774916667 0.465375,0.465375 C0.774916667,0.155125 1.15104167,0 1.59375,0 L15.40625,0 C15.8489583,0 16.2250833,0.155125 16.534625,0.465375 C16.844875,0.774916667 17,1.15104167 17,1.59375 L17,4.78125 C17,5.12054167 16.9079167,5.4304375 16.72375,5.7109375 C16.538875,5.9914375 16.2767917,6.18339583 15.9375,6.2868125 L15.9375,15.40625 C15.9375,15.8489583 15.782375,16.2250833 15.472125,16.534625 C15.1625833,16.844875 14.7864583,17 14.34375,17 L2.65625,17 C2.21354167,17 1.83741667,16.844875 1.527875,16.534625 C1.217625,16.2250833 1.0625,15.8489583 1.0625,15.40625 Z M2.65625,6.375 L2.65625,15.40625 L14.34375,15.40625 L14.34375,6.375 L2.65625,6.375 Z M15.40625,4.78125 L15.40625,1.59375 L1.59375,1.59375 L1.59375,4.78125 L15.40625,4.78125 Z M6.375,10.09375 L10.625,10.09375 L10.625,8.5 L6.375,8.5 L6.375,10.09375 Z M2.65625,15.40625 L2.65625,6.375 L2.65625,15.40625 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.actionBtnEdit {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / edit%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='create-24px'%3E%3Cpolygon id='Path' points='0 0 20 0 20 20 0 20'%3E%3C/polygon%3E%3Cpath d='M2.5,14.375 L2.5,17.5 L5.625,17.5 L14.8416667,8.28333333 L11.7166667,5.15833333 L2.5,14.375 Z M4.93333333,15.8333333 L4.16666667,15.8333333 L4.16666667,15.0666667 L11.7166667,7.51666667 L12.4833333,8.28333333 L4.93333333,15.8333333 Z M17.2583333,4.69166667 L15.3083333,2.74166667 C15.1416667,2.575 14.9333333,2.5 14.7166667,2.5 C14.5,2.5 14.2916667,2.58333333 14.1333333,2.74166667 L12.6083333,4.26666667 L15.7333333,7.39166667 L17.2583333,5.86666667 C17.5833333,5.54166667 17.5833333,5.01666667 17.2583333,4.69166667 Z' id='Shape' fill='%23697094' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.rssLastError {
  word-wrap: break-word;
  background-color: $color-dark-alt;
  color: $color-white;
  padding: 12px;
  border-radius: 6px;
}