@import 'theme/common-style.scss';

.root {
  position: relative;
}

.datepickerWrapper {
  @include input-box(transparent);
  height: 34px;

  input {
    width: 100%;
  }
}

.icon {
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
}
