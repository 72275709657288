@mixin btn-pseudo-states {
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.8;

    &:disabled {
      opacity: 0.5;
    }
  }
}

@mixin btn-base {
  @include btn-pseudo-states;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s ease-in-out;
}

@mixin common-btn {
  @include font(16, 500);
  @include btn-pseudo-states;
  @include flex-container(center, center);
  position: relative;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  border-radius: 999px;
  color: $color-white;
  text-align: center;
  white-space: nowrap;

  svg {
    margin-right: 10px;
  }
}

@mixin gray-btn() {
  @include btn-base;
  @include font(16, medium);
  @include flex-container(center, center);
  background-color: $color-secondary;
  color: $color-primary;
  width: 100%;
  margin-top: 2em;
  padding: 10px 0;
  border-radius: 20px;

  span {
    margin-left: 11px;
  }
}

@mixin btn-primary {
  @include common-btn;
  background: $color-primary-gradient;
}

@mixin btn-cancel {
  @include common-btn;
  background-color: $color-error-alt;
}

@mixin btn-success {
  @include common-btn;
  background-color: $color-success;
}

@mixin btn-transparent {
  @include common-btn;
  @include font(15, 500);
  background-color: transparent;
  color: $color-label;
  padding: 10px 0;
}

@mixin btn-mini {
  @include font(10, 500);
  background: $color-primary-gradient;
  padding: 5px 14px;
  border-radius: 999px;
  color: $color-white;
  position: relative;
  text-align: center;
}

@mixin btn-mini-reverted {
  @include btn-mini;
  background: $color-white;
  border: 1px solid $color-primary;
  color: $color-primary;
}

@mixin btn-mini-reverted-error {
  @include btn-mini;
  background: $color-white;
  border: 1px solid $color-error-alt;
  color: $color-error-alt;
}
@mixin btn-page-number {
  @include font(14, 600);
  @include btn-pseudo-states;
  @include flex-container(center, center);
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 999px;
  padding: 5px;
  width: 25px;
  height: 25px;
  background: $color-table-background;
  color: $color-gray-table-cell;
  text-align: center;
  white-space: nowrap;

  &:active {
    background: $color-primary-gradient;
    color: $color-white;
  }
}

@mixin buttons {
  button {
    @include btn-base;
  }

  .btn-primary {
    @include btn-primary;
  }

  .btn-cancel {
    @include btn-cancel;
  }

  .btn-success {
    @include btn-success;
  }

  .btn-transparent {
    @include btn-transparent;
  }

  .btn-mini {
    @include btn-mini;
  }

  .btn-mini-reverted {
    @include btn-mini-reverted;
  }

  .btn-mini-reverted-error {
    @include btn-mini-reverted-error;
  }

  .btn-page-number {
    @include btn-page-number;
  }
}
