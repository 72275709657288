@import 'theme/common-style.scss';

.actions {
  @include flex-container(center, center);
  margin-top: 10px;
}

.withPadding {
  @include flex-container(flex-start, center);
  padding-left: 52px;

  @include screen-xl-minus {
    padding-left: 44px;
  }
}

button ~ div,
button ~ button {
  margin-left: 6px;
}