@import 'theme/common-style.scss';

.root {
  @include flex-container(space-between, center);
  padding: 12px 16px;
  border-left: 1px solid $color-gray-border-alt;
  border-right: 1px solid $color-gray-border-alt;
  border-bottom: 1px solid $color-gray-border-alt;
}

.buttons {
  @include flex-container(flex-end, center);
  justify-self: self-end;
  flex-grow: 2;

  button {
    @include flex-container(center, center);
    min-width: 25px;
    height: 25px;
    padding: 0 8px;
  }
}

.btn,
.info {
  @include font(15);
  color: $color-label;
}

.arrowBtnLeft {
  margin-right: 8px;
}

.arrowBtnRight {
  margin-left: 8px;
}

.activePage {
  color: $color-white;
  background-color: $color-label;
}
