@import 'theme/common-style.scss';

.formCheckbox {
  display: block;
  position: relative;
  user-select: none;
  display: flex;

  .formCheckboxCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid $color-gray-light;
    border-radius: 4px;

    &::after {
      position: absolute;
      content: '';
      display: none;
      left: 2px;
      top: 0px;
      width: 6px;
      height: 8px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:hover ~ .formCheckboxCheckmark {
    background-color: $color-gray-light;
  }

  input:checked ~ .formCheckboxCheckmark {
    background-color: $color-primary-light;
    border: 1px solid $color-primary-light;

    &:after {
      display: block;
    }
  }
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label {
  @include font(14);
  position: relative;
  top: -1px;
  padding-left: 25px;
  color: $color-font;
  cursor: pointer;
  line-height: 1;
}

.disabled {
  cursor: auto;
}
