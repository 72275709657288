@import 'theme/common-style.scss';

.login {
  @include flex-container(center, center);
  width: 100vw;
  height: 100vh;
}

.loginCard {
  width: 325px;
  box-shadow: $box-shadow;
}

.header {
  @include flex-container(center, center);
  background-color: $color-dark;
  height: 115px;
  border-radius: 8px 8px 0 0;
}

.logo {
  width: 90px;
  height: 41px;

  path {
    fill: $color-white;
  }
}

.body {
  background-color: $color-white;
  padding: 29px 25px 36px;
  border-radius: 0 0 8px 8px;
}
