@mixin datepicker {
  .react-datepicker,
  .react-datepicker__header {
    background-color: $color-white;
  }

  .react-datepicker__header {
    border-bottom: none;
    margin-top: 8px;
  }

  .react-datepicker-popper {
    z-index: 9;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: $color-white;
  }

  .react-datepicker__day {
    @include font(12, bold);
    @include flex-container(center, center, $inline: true);
    color: $color-label;
    width: 35px;
    height: 35px;
    margin: 0.8px;
  }

  .react-datepicker__day-name {
    @include font(12, bold);
    color: $color-datepicker-day-name;
    width: 35px;
    height: 35px;
    margin: 0.8px;
  }

  .react-datepicker__day-names {
    margin-top: 16px;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 3px;
    background-color: $color-datepicker-day-name-selected;
    color: $color-white;
    box-shadow: $color-datepicker-day-name-selected-box-shadow;
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    border-radius: 3px;
    background-color: $color-datepicker-day-outside-month-bg;
    color: $color-datepicker-day-outside-month-text;
  }

  .react-datepicker__day--disabled:hover {
    background-color: $color-datepicker-day-outside-month-bg;
  }

  .react-datepicker__current-month {
    @include font(16, 600);
    color: $color-text;
  }

  .react-datepicker__navigation-icon::before {
    top: 18px;
    border-color: $color-gray-cool;
  }

  .react-datepicker__close-icon::after {
    @include font(18, bold);
    background-color: $color-white;
    color: $color-datepicker-close-icon;
    transform: translateY(-1px);

    @include screen-xl-minus {
      transform: translateY(-4px);
    }
  }
}