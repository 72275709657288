@import 'theme/common-style.scss';

.layoutSideMenu {
  margin-bottom: 32px;
}

.marginTop {
  margin-top: 90px;
}

.link {
  @include font(15);
  background-color: $color-white;

  > a {
    @include menu-link;
    padding: 11px 0 11px 13.5px;
    border-radius: inherit;

    svg {
      margin-right: 11px;
    }
  }

  // &:first-child {
  //   border-radius: 20px 20px 0 0;
  // }
  // &:last-child {
  //   border-radius: 0 0 20px 20px;
  // }

  &Active {
    background: $color-primary-gradient;

    span {
      color: $color-white;
    }
    svg path:last-child {
      fill: $color-white;
    }
  }
}

.sublink {
  height: 42px;
  background-color: $color-gray-light-alt2;
  border-bottom: 1px solid $color-white;

  > a {
    @include menu-link;
    padding-left: 48px;
    padding-top: px;
    height: 100%;
  }

  &Active {
    background: $color-primary-gradient;

    span {
      color: $color-white;
    }
  }
}


.dropdownMenuIcon {
  @include flex-container($align-items: center);
  padding-left: 60px;

  @include screen-xl-minus {
    padding-left: 46px;
  }
}
