@import 'theme/common-style.scss';

.root {
  @include flex-container($flex-flow: column);
  gap: 16px;
  flex: 1;
}

.contentUrl {
  @include flex-container;
  @include font(13);

  strong {
    white-space: nowrap;
    padding-right: 10px;
  }

  a {
    word-break: break-all;
  }
}

.card {
  padding: 15px;
  background-color: $color-white;
}

.post {
  padding: 36px 20px;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  @include font(21, $font-weight-bold);
  line-height: 24px;
  color: $color-font;
}

.message {
  @include font(17, $font-weight-normal);
  line-height: 24px;
  color: $color-font;
}

.photo {
  width: 100%;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
}

.audio {
  width: 100%;
}

.keypoints ul {
  @include font(17, $font-weight-normal);
  list-style: disc;
  padding-left: 20px;
}

.openaiResponseJson {
  padding: 24px;

  &Title {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray-border-alt;
  }

  &Summary {

    h2 {
      margin-bottom: 14px;
    }

    h3 {
      margin: 20px 0 12px;
    }

    a {
      color: $color-orange;

      &:hover {
        text-decoration: underline;
      }
    }

    p, ol, ul {
      @include font(13);
      margin-bottom: 12px;
    }

    ol, ul {
      padding-left: 20px;
    }
  }

  h3 {
    margin-bottom: 16px;
  }

  pre {
    @include font(14);
    white-space: pre-wrap;
    word-break: break-all;
  }
}