@import 'theme/common-style.scss';

.field {
  border-radius: 8px;
  padding: 14px 25px 30px;
  border: solid 1px $color-gray-border;
  background-clip: padding-box;
}

.fieldTitle {
  @include font(13, 700);
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  color: $color-font-secondary;

  span {
    @include font(13, 400);
    color: $color-font-secondary;
    text-transform: none;
    margin-left: 10px;
  }
}

.sourceIcon {
  width: 16px;
  height: 16px;
}

.fields {
  display: grid;
  row-gap: 25px;
  margin-top: 30px;
}

.sectionsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.submitBtn {
  @include font(16, 500);
  background: $color-primary-gradient;
  padding: 10px 50px;
  border-radius: 999px;
  color: $color-white;
  margin-left: auto;
  position: relative;
  text-align: center;
}

.submitBtnText {
  &.loading {
    opacity: 0;
    visibility: 0;
  }
}

.errorMessage {
  @include font(13, 400);
  color: $color-error;
  margin-top: 12px;
}

.checkbox {
  margin-top: 30px;

  label {
    font-size: 13px;
    font-weight: 500;
    color: $color-font-secondary;
  }
}

.geolocationByBox {
  display: grid;
  row-gap: 5px;
  margin-bottom: 10px;
}

.latiLongBox {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.geolocationFieldsBox {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.fetchLocationBtnBox {
  display: flex;
  align-items: flex-end;
}

.fetchLocationBtn {
  background: transparent;
  border: 1px solid $color-primary;
  @include font(16, 500);
  line-height: 25px;
  padding: 7px 50px;
  width: 100%;
  border-radius: 999px;
  color: $color-primary;
}

.checkboxes {
  @include flex-container;

  > div {
    flex: 1 1 50%;
  }
}