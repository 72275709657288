@import 'theme/common-style.scss';

.root {
  @include flex-container(center, center);
  width: 100vw;
  height: calc(100vh - $misclassifications-action-bar-height);
}

.iconBox {
  @include flex-container(center, center);
  background-color: rgba($color-white, 0.63);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin-right: 22px;
}

.message {
  @include flex-container(flex-start, center);
  @include font(22, $font-weight-bold);
  max-width: 515px;
  width: fit-content;
  height: 200px;
  padding: 16px 45px 16px 40px;
}

.error {
  color: $color-error-alt;
  background-color: $color-error-alt-light;

  .iconBox {
    path:last-of-type {
      fill: $color-error-alt;
    }
  }
}

.success {
  color: $color-success-alt;
  background-color: $color-success-alt-light;
}