@import 'theme/common-style.scss';

.root {
  display: flex;
  cursor: pointer;

  span {
    white-space: nowrap;
  }
}

.icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 11px;

  @include screen-xl-minus {
    margin-right: 8px;
  }
}

.Archive,
.Delete {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / delete%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='delete-24px'%3E%3Cpolygon id='Path' points='0 0 20 0 20 20 0 20'%3E%3C/polygon%3E%3Cpath d='M13.3333333,7.5 L13.3333333,15.8333333 L6.66666667,15.8333333 L6.66666667,7.5 L13.3333333,7.5 M12.0833333,2.5 L7.91666667,2.5 L7.08333333,3.33333333 L4.16666667,3.33333333 L4.16666667,5 L15.8333333,5 L15.8333333,3.33333333 L12.9166667,3.33333333 L12.0833333,2.5 Z M15,5.83333333 L5,5.83333333 L5,15.8333333 C5,16.75 5.75,17.5 6.66666667,17.5 L13.3333333,17.5 C14.25,17.5 15,16.75 15,15.8333333 L15,5.83333333 Z' id='Shape' fill='%23697094' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.Edit {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / edit%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='create-24px'%3E%3Cpolygon id='Path' points='0 0 20 0 20 20 0 20'%3E%3C/polygon%3E%3Cpath d='M2.5,14.375 L2.5,17.5 L5.625,17.5 L14.8416667,8.28333333 L11.7166667,5.15833333 L2.5,14.375 Z M4.93333333,15.8333333 L4.16666667,15.8333333 L4.16666667,15.0666667 L11.7166667,7.51666667 L12.4833333,8.28333333 L4.93333333,15.8333333 Z M17.2583333,4.69166667 L15.3083333,2.74166667 C15.1416667,2.575 14.9333333,2.5 14.7166667,2.5 C14.5,2.5 14.2916667,2.58333333 14.1333333,2.74166667 L12.6083333,4.26666667 L15.7333333,7.39166667 L17.2583333,5.86666667 C17.5833333,5.54166667 17.5833333,5.01666667 17.2583333,4.69166667 Z' id='Shape' fill='%23697094' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.Add {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h20v20H0z'/%3E%3Cpath fill='%23697094' fill-rule='nonzero' d='M15.833 10.833h-5v5H9.167v-5h-5V9.167h5v-5h1.666v5h5z'/%3E%3C/g%3E%3C/svg%3E");
}
.Export {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon / export%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icon-/-export' fill='%23697094' fill-rule='nonzero'%3E%3Cg id='upgrade_FILL0_wght400_GRAD0_opsz20' transform='translate(6.000000, 3.500000)'%3E%3Cpath d='M0,13 L0,11.5 L8,11.5 L8,13 L0,13 Z M3.25,10 L3.25,2.875 L1.062,5.062 L0,4 L4,0 L8,4 L6.938,5.062 L4.75,2.875 L4.75,10 L3.25,10 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}