@import 'theme/common-style.scss';

.followedFilters {
  position: sticky;
  top: 0;
  background-color: $color-white;
  padding-top: 23px;
  padding-bottom: 29px;
  z-index: 3;
}

.followedFiltersLabel {
  @include font(15, medium);
  color: $color-label;
}

.lists {
  display: grid;
  grid-template-columns: repeat(5, 257px);
  gap: 10px;
  margin-top: 9px;

  @include screen-xl-minus {
    grid-template-columns: repeat(5, 200px);
  }
  @include screen-hg-minus {
    grid-template-columns: repeat(4, 175px);
  }
  @include screen-md-minus {
    grid-template-columns: repeat(3, 175px);
  }
}

.filtersListsInModal {
  height: 340px;
}

.filterMultiselect button {
  width: 100%;
}
